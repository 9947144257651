<template>
  <b-modal
    id="receive_installment_modal"
    :title="$t('Baixa do recebimento')"
    centered
    size="xl"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    @hidden="cleanModalData"
  >
    <e-spinner v-if="loading" />
    <b-card-actions
      v-else
      :title="$t('Dados do recebimento')"
      :show-loading="saving"
      no-actions
    >
      <FormulateForm
        ref="modalReceiveForm"
        name="modalReceiveForm"
      >
        <b-row v-if="!isBulkReceive">
          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-value"
              v-model="installmentReceiveForm.value"
              type="label"
              filter="currency"
              :label="$t('Valor da parcela')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-commission"
              v-model="installmentReceiveForm.commissionValue"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Taxa')"
              @blur="(value) => onInputCommission(value, installmentReceiveForm)"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-addition"
              v-model="installmentReceiveForm.additionValue"
              :type="installmentReceiveForm.isPartialPayment ? 'label' : 'text-number'"
              currency="R$"
              :precision="2"
              :label="$t('Acréscimo')"
              filter="currency"
              @blur="(value) => onInputAddition(value, installmentReceiveForm)"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-decrease"
              v-model="installmentReceiveForm.decreaseValue"
              :type="installmentReceiveForm.isPartialPayment ? 'label' : 'text-number'"
              currency="R$"
              :precision="2"
              :label="$t('Decréscimo')"
              filter="currency"
              @blur="(value) => onInputDecrease(value, installmentReceiveForm)"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-paid_value"
              v-model="installmentReceiveForm.paidValue"
              :type="
                installmentReceiveForm.canPayPartial && installmentReceiveForm.isPartialPayment
                  ? 'text-number'
                  : 'label'
              "
              filter="currency"
              currency="R$"
              :precision="2"
              :label="$t('Valor recebido')"
              :instruction="
                $t('Valor recebido com [Taxa], [Acréscimo] e [Decréscimo] já calculados.')
              "
              :validation="
                installmentReceiveForm.isPartialPayment
                  ? [['max', installmentReceiveForm.value]]
                  : []
              "
              :validation-messages="{
                max: `Valor deve ser menor que ${$options.filters.currency(
                  installmentReceiveForm.value
                )}`,
              }"
            />
          </b-col>
          <b-col
            v-if="installmentReceiveForm.canPayPartial"
            md="2"
          >
            <FormulateInput
              v-if="installmentReceiveForm.canPayPartial"
              id="modal_receive_installment-is_partial_payment"
              v-model="installmentReceiveForm.isPartialPayment"
              name="isPartialPayment"
              type="switch"
              :label="$t('Recebimento parcial?')"
              @change="(value) => onChangeIsPartialPayment(value, installmentReceiveForm)"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="modal_receive_installment-is_other_payment_method"
              v-model="installmentReceiveForm.isOtherPaymentMethod"
              name="isOtherPaymentMethod"
              type="switch"
              :label="$t('Outra forma de pagamento?')"
            />
          </b-col>
          <b-col
            v-if="installmentReceiveForm.isOtherPaymentMethod"
            md="4"
          >
            <e-payment-method
              id="modal_receive_installment-other_payment_method_id"
              v-model="installmentReceiveForm.otherPaymentMethodId"
              :required="installmentReceiveForm.isOtherPaymentMethod"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <e-checking-account-combo
              id="modal_receive_installment-input_checking_account"
              v-model="installmentReceiveForm.checkingAccountId"
              type="All"
              :store-id="storeId"
              required
              active-options
              @single-option="
                (checkAccId) => (installmentReceiveForm.checkingAccountId = checkAccId)
              "
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="modal_receive_installment-input_receivement_date"
              ref="inputReceivementDate"
              v-model="installmentReceiveForm.paymentDate"
              type="datepicker"
              :hour-default="12"
              :label="$t('Data recebimento')"
              :placeholder="$t('dd/mm/aaaa')"
              class="required"
              validation="required"
            />
          </b-col>
        </b-row>

        <b-row v-if="isBulkReceive">
          <b-col>
            <FormulateInput
              id="modal_pay_installment-group_items"
              v-model="groupItems"
              type="switch"
              :label="$t('Agrupar baixas?')"
              :instructions="[
                {
                  text: $t(
                    'Será criado apenas um lançamento na conta corrente com o valor da soma de todas as parcelas marcadas para pagamento'
                  ),
                },
                {
                  text: $t(
                    'O estorno de uma parcela baixada em grupo implicará no estorno de todas as parcelas que foram baixadas juntas'
                  ),
                },
                {
                  text: $t(
                    'Os agrupamentos serão realizados por Forma de Pagamento e Bandeira do Cartão (quando houver)'
                  ),
                },
              ]"
            />
          </b-col>
        </b-row>

        <b-row v-if="isBulkReceive">
          <b-col>
            <b-table
              show-empty
              responsive
              class="bordered"
              :fields="fields"
              :items="installmentBulkReceive"
            >
              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="false"
                  :show-delete="true"
                  @update="() => {}"
                  @delete="onRemoveInstallment(row.item)"
                />
              </template>
              <template #cell(commissionValue)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-commission-${row.index}`"
                  v-model="row.item.commissionValue"
                  type="text-number"
                  currency="R$"
                  :precision="2"
                  @blur="(value) => onInputCommission(value, row.item)"
                />
              </template>
              <template #cell(additionValue)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-addition-${row.index}`"
                  v-model="row.item.additionValue"
                  :type="row.item.isPartialPayment ? 'label' : 'text-number'"
                  currency="R$"
                  :precision="2"
                  filter="currency"
                  @blur="(value) => onInputAddition(value, row.item)"
                />
              </template>
              <template #cell(decreaseValue)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-decrease-${row.index}`"
                  v-model="row.item.decreaseValue"
                  :type="row.item.isPartialPayment ? 'label' : 'text-number'"
                  currency="R$"
                  :precision="2"
                  filter="currency"
                  @blur="(value) => onInputDecrease(value, row.item)"
                />
              </template>

              <template #cell(paidValue)="row">
                <FormulateInput
                  :id="`modal_bulk_pay_installment-paid_value-${row.index}`"
                  v-model="row.item.paidValue"
                  :type="
                    row.item.canPayPartial && row.item.isPartialPayment ? 'text-number' : 'label'
                  "
                  filter="currency"
                  currency="R$"
                  :precision="2"
                  :validation="row.item.isPartialPayment ? [['max', row.item.value]] : []"
                  :validation-messages="{
                    max: `Valor deve ser menor ou igual a ${$options.filters.currency(
                      row.item.value
                    )}`,
                  }"
                />
              </template>

              <template #cell(isPartialPayment)="row">
                <b-col
                  v-if="row.item.canPayPartial"
                  md="2"
                >
                  <FormulateInput
                    :id="`modal_bulk_pay_installment-is_partial_payment-${row.index}`"
                    v-model="row.item.isPartialPayment"
                    :name="`isPartialPayment${row.index}`"
                    type="switch"
                    label=" "
                    @change="(value) => onChangeIsPartialPayment(value, row.item)"
                  />
                </b-col>
                <template v-else>
                  <span :title="$t('Forma de pagamento não permite')"> - </span>
                </template>
              </template>

              <template #cell(otherPaymentMethod)="row">
                <b-col md="12">
                  <FormulateInput
                    :id="`modal_bulk_pay_installment-is_other_payment_method-${row.index}`"
                    v-model="row.item.isOtherPaymentMethod"
                    :name="`isOtherPaymentMethod${row.index}`"
                    type="switch"
                    label=" "
                  />
                </b-col>
                <e-payment-method
                  v-if="row.item.isOtherPaymentMethod"
                  :id="`modal_bulk_pay_installment-other_payment_method_id-${row.index}`"
                  v-model="row.item.otherPaymentMethodId"
                  :name="`otherPaymentMethodId${row.index}`"
                  :required="true"
                  :show-label="false"
                  class="mt-1"
                />
              </template>

              <template #custom-foot>
                <tr>
                  <th
                    colspan="5"
                    class="text-right"
                  >
                    Total
                  </th>
                  <th class="text-right">
                    {{ getTotals.valueTotal | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotals.commissionTotal | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotals.additionTotal | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotals.decreaseTotal | currency }}
                  </th>
                  <th class="text-right">
                    {{ getTotals.paidTotal | currency }}
                  </th>
                  <th />
                </tr>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-else />
      </FormulateForm>
    </b-card-actions>

    <b-row class="mt-1 justify-content-end">
      <b-col class="d-flex justify-content-end">
        <e-button
          id="modal_receive_installment-btn_cancel"
          class="mr-1"
          variant="outline-primary"
          :text="$t('Cancelar')"
          :text-shortcuts="['ESC']"
          @click="hideModal"
        />

        <e-button
          id="modal_receive_installment-btn_confirm"
          variant="primary"
          icon="cash"
          :text="$t('Confirmar baixa')"
          :busy="saving"
          @click="onConfirm"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { BModal, BRow, BCol, BTable } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { alerts, statusTypes } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESpinner from '@/views/components/ESpinner.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import ECheckingAccountCombo from '@/views/components/inputs/ECheckingAccountCombo.vue'
import Decimal from 'decimal.js-light'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'

const getInitialInstallmentReceiveForm = () => ({
  id: null,
  checkingAccountId: null,
  paymentDate: null,
  paidValue: 0,
  commissionValue: 0,
  additionValue: 0,
  decreaseValue: 0,
  value: 0,
  canPayPartial: false,
  isPartialPayment: false,
  isOtherPaymentMethod: false,
  otherPaymentMethodId: null,
})

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    EButton,
    BCardActions,
    ESpinner,
    EGridActions,
    ECheckingAccountCombo,
    EPaymentMethod,
  },

  mixins: [alerts, statusTypes],

  props: {},

  data() {
    return {
      loading: false,
      saving: false,
      isBulkReceive: false,
      installmentReceiveForm: getInitialInstallmentReceiveForm(),
      installmentBulkReceive: [],
      storeId: null,
      groupItems: false,
    }
  },

  computed: {
    getTotals() {
      return this.installmentBulkReceive.reduce(
        (totals, inst) => ({
          valueTotal: _.toNumber(totals.valueTotal) + inst.value,
          paidTotal: _.toNumber(totals.paidTotal) + inst.paidValue,
          commissionTotal: _.toNumber(totals.commissionTotal) + inst.commissionValue,
          additionTotal: _.toNumber(totals.additionTotal) + inst.additionValue,
          decreaseTotal: _.toNumber(totals.decreaseTotal) + inst.decreaseValue,
        }),
        {
          valueTotal: 0,
          paidTotal: 0,
          commissionTotal: 0,
          additionTotal: 0,
          decreaseTotal: 0,
        }
      )
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Descrição'),
          key: 'receivable.description',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { minWidth: '150px' },
        },
        {
          label: this.$t('Recebimento parcial'),
          key: 'isPartialPayment',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Parcela'),
          key: 'installment',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Vencimento'),
          key: 'dueDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.date(val),
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Comissão'),
          key: 'commissionValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
        },
        {
          label: this.$t('Acréscimo'),
          key: 'additionValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
        },
        {
          label: this.$t('Decréscimo'),
          key: 'decreaseValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
        },
        {
          label: this.$t('Valor recebido'),
          key: 'paidValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Outra forma de pgto.'),
          key: 'otherPaymentMethod',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '180px', minWidth: '180px' },
        },
      ]
    },
  },

  methods: {
    async onConfirm() {
      if (!this.validForm()) {
        this.showInvalidDataMessage()
        return
      }

      this.saving = true
      try {
        if (this.isBulkReceive) {
          const payload = this.installmentBulkReceive.map(inst =>
            this.prepareToSaveReceive(this.installmentReceiveForm, inst)
          )
          await this.$http.patch('/api/accounting/receivable-installments/bulk-receive', {
            receivableInstallments: payload,
            groupItems: this.groupItems,
          })
          this.showSuccess({ message: this.$t('Recebimento em lote concluído.') })
        } else {
          const payload = this.prepareToSaveReceive(this.installmentReceiveForm)
          await this.$http.patch('/api/accounting/receivable-installments/receive', payload)
          this.showSuccess({ message: this.$t('Recebimento concluído.') })
        }

        const itemList = this.isBulkReceive
          ? this.installmentBulkReceive
          : [this.installmentReceiveForm]
        this.$emit('after-confirm', { itemList })
        this.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    prepareToSaveReceive(paymentForm, data = getInitialInstallmentReceiveForm()) {
      return {
        ...data,
        id: data.id || paymentForm.id,
        checkingAccountId: paymentForm.checkingAccountId,
        paymentDate: paymentForm.paymentDate,
        paidValue: data.paidValue || paymentForm.paidValue,
        commissionValue: data.commissionValue || paymentForm.commissionValue,
        additionValue: data.additionValue || paymentForm.additionValue,
        decreaseValue: data.decreaseValue || paymentForm.decreaseValue,
        financialWriteOffOtherPaymentMethod:
          data.isOtherPaymentMethod || paymentForm.isOtherPaymentMethod,
        financialWriteOffPaymentMethodId:
          data.otherPaymentMethodId || paymentForm.otherPaymentMethodId,
      }
    },

    async showModal(isBulk, installmentsList) {
      this.loading = true
      this.$bvModal.show('receive_installment_modal')
      this.isBulkReceive = isBulk

      try {
        const { data } = await this.$http.get(
          '/api/accounting/receivable-installments/list-to-receive',
          { params: { receivableInstallmentIds: installmentsList } }
        )

        if (!data?.length) {
          throw new Error(this.$t('Parcela a pagar não encontrada.'))
        }

        this.storeId = data[0].receivable.store.id
        const defaultData = getInitialInstallmentReceiveForm()
        if (isBulk) {
          this.installmentBulkReceive = data.map(inst => ({
            ...defaultData,
            ...inst,
            paidValue: this.calculateValueToReceive(
              inst.value,
              inst.commissionValue,
              inst.additionValue,
              inst.decreaseValue
            ),
            paymentDate: defaultData.paymentDate,
            canPayPartial: this.validateCanPayPartial(inst),
          }))
        } else {
          const installment = data[0]
          this.installmentReceiveForm = {
            ...defaultData,
            ...installment,
            paidValue: this.calculateValueToReceive(
              installment.value,
              installment.commissionValue,
              installment.additionValue,
              installment.decreaseValue
            ),
            paymentDate: defaultData.paymentDate,
            canPayPartial: this.validateCanPayPartial(installment || {}),
          }
        }
      } catch (error) {
        this.showError({ error })
        this.hideModal()
      } finally {
        this.loading = false
      }
    },
    cleanModalData() {
      this.installmentReceiveForm = getInitialInstallmentReceiveForm()
      this.installmentBulkReceive = []
      this.storeId = null
    },
    hideModal() {
      this.cleanModalData()
      this.$bvModal.hide('receive_installment_modal')
    },
    onInputCommission(commissionValue, item) {
      if (item.isPartialPayment) return

      // eslint-disable-next-line no-param-reassign
      item.paidValue = this.calculateValueToReceive(
        item.value,
        commissionValue,
        item.additionValue,
        item.decreaseValue
      )
    },
    onInputAddition(additionValue, item) {
      if (item.isPartialPayment) return

      // eslint-disable-next-line no-param-reassign
      item.paidValue = this.calculateValueToReceive(
        item.value,
        item.commissionValue,
        additionValue,
        item.decreaseValue
      )
    },
    onInputDecrease(decreaseValue, item) {
      if (item.isPartialPayment) return

      // eslint-disable-next-line no-param-reassign
      item.paidValue = this.calculateValueToReceive(
        item.value,
        item.commissionValue,
        item.additionValue,
        decreaseValue
      )
    },
    // eslint-disable-next-line func-names
    onChangeIsPartialPayment: _.debounce(function (value, item) {
      if (!item.canPayPartial) return

      /* eslint-disable no-param-reassign */
      item.commissionValue = 0
      item.additionValue = 0
      item.decreaseValue = 0

      item.paidValue = this.calculateValueToReceive(
        item.value,
        item.commissionValue,
        item.additionValue,
        item.decreaseValue
      )
      /* eslint-enable no-param-reassign */
    }, 200),
    calculateValueToReceive(originalValue, commission, addition, decrease) {
      const result = new Decimal(originalValue || 0)
        .sub(decrease || 0)
        .sub(commission || 0)
        .add(addition || 0)
        .toNumber()
      if (!result) return 0
      return result
    },
    onRemoveInstallment(item) {
      const indexFound = this.installmentBulkReceive.findIndex(
        installment => installment.id === item.id
      )
      this.installmentBulkReceive.splice(indexFound, 1)
      this.$emit('remove-installment', item)
    },

    validateCanPayPartial(installment) {
      const { receivable } = installment
      return (
        receivable.recordType !== this.recordTypeEnum.MANUAL &&
        receivable?.paymentMethod?.allowReceivablePartialWriteOff
      )
    },
    validForm() {
      this.$refs.modalReceiveForm.showErrors()
      const { paymentDate, checkingAccountId, isOtherPaymentMethod, otherPaymentMethodId } =
        this.installmentReceiveForm

      if (!paymentDate) return false
      this.$refs.inputReceivementDate.validationErrors = [] // reseta os erros persistentes do formulateInput

      if (!checkingAccountId) return false

      if (isOtherPaymentMethod && !otherPaymentMethodId) return false
      if (this.isBulkReceive) {
        if (
          this.installmentBulkReceive.some(i => i.isOtherPaymentMethod && !i.otherPaymentMethodId)
        )
          return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>
